import React, {useContext, createRef} from "react";
import Headroom from "react-headroom";
import "./Header.scss";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import StyleContext from "../../contexts/StyleContext";

import {
  greeting,
  workExperiences,
  techStack,
  headerLogo
} from "../../portfolio";

function Header() {
  const {isDark} = useContext(StyleContext);
  const viewExperience = workExperiences.display;
  const viewSkills = techStack.viewSkillBars;
  const imgRef = createRef();

  return (
    <Headroom>
      <header className={isDark ? "dark-menu header" : "header"}>
        <a href="/" className="logo">
          <img
                ref={imgRef}
                className="logo-image"
                src={headerLogo.logo}
                alt={headerLogo.name}
          />
          <span className="logo-name">{greeting.username}</span>
        </a>
        <ul className={isDark ? "dark-menu menu" : "menu"}>
          {viewSkills && (
            <li>
              <a href="#tecSkills">SKILLS</a>
            </li>
          )}
          {viewExperience && (
            <li>
              <a href="#experience">EXPERIENCE</a>
            </li>
          )}
          <li>
            <a href="#contact">CONTACT ME</a>
          </li>
          <li>
            <a href="/">
              <ToggleSwitch />
            </a>
          </li>
        </ul>
      </header>
    </Headroom>
  );
}
export default Header;
