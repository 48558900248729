import React, { useContext } from "react";
import { Fade } from "react-reveal";
import "./Greeting.scss";
import Button from "../../components/button/Button";
import { greeting } from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";

export default function Greeting() {
  const { isDark } = useContext(StyleContext);
  if (!greeting.displayGreeting) {
    return null;
  }
  return (
    <Fade bottom duration={1000} distance="40px">
      <div className="greeting-main" id="greeting">
        <div className="greeting-text-div">
          <div>
            <h1 className={isDark ? "dark-mode greeting-text" : "greeting-text"}>
              {" Hi, I'm"}<br />
              {greeting.title}<br />
              {""}
            </h1>
            <p
              className={
                isDark
                  ? "dark-mode greeting-text-p"
                  : "greeting-text-p subTitle"
              }
            >
              {greeting.subTitle}<br></br>
              {"Proficient in both Frontend and Backend technologies such as Angular, React, Java, Spring Boot, AWS, and Azure. I actively seek out innovative solutions to stay ahead in the tech space."}
            </p>
            <div className="button-greeting-div">
              <Button text="Contact me" href="#contact" />
              {greeting.resumeLink && (
                <a
                  href={require("./resume.pdf")}
                  download="Resume.pdf"
                  className="download-link-button"
                >
                  <Button text="Download my resume" />
                </a>
              )}
            </div>
          </div>
        </div>
        <div className="greeting-image-div">
          <img
            alt="profile"
            src={require("../../assets/images/profileJob.jpg")}>
          </img>
        </div>
      </div>
    </Fade>
  );
}
