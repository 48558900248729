import React, { useContext } from "react";
import "./Education.scss";
import EducationCard from "../../components/educationCard/EducationCard";
import { educationInfo } from "../../portfolio";
import { Fade } from "react-reveal";
import StyleContext from "../../contexts/StyleContext";

export default function Education() {
  const { isDark } = useContext(StyleContext);
  if (!educationInfo.display) {
    return null;
  }

  return (
    <Fade bottom duration={1000} distance="40px">
      <div className={isDark ? "dark-mode main" : "main"} id="education">
        <div className="education-section">
          <h1 className="education-heading">EDUCATION</h1>
          <div className="education-card-container">
            {educationInfo.schools.map((school, index) => (
              <EducationCard key={index} school={school} />
            ))}
          </div>
        </div>
      </div>
    </Fade>
  );
}
