import React, { useContext, useState } from "react";
import axios from "axios";
import "./Contact.scss";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import { contactInfo } from "../../portfolio";
import { Fade } from "react-reveal";
import StyleContext from "../../contexts/StyleContext";

export default function Contact() {
  const { isDark } = useContext(StyleContext);
  const [form, setForm] = useState({
    nameContact: '',
    email: '',
    phoneNumber: '',
    subject: '',
    message: ''
  });
  const [buttonText, setButtonText] = useState('Send message');
  const [isProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setButtonText('Sending...');

    const data = {
      nameContact: form.nameContact,
      email: form.email,
      phoneNumber: form.phoneNumber,
      subject: form.subject,
      message: form.message
    };

    await axios.post('https://dphuor2kld.execute-api.us-east-1.amazonaws.com/v1/contact', data)
      .then(response => response.status)
      .catch(error => error);

    setTimeout(() => {
      alert("Thank you. I will get back to you as soon as possible.")
      setButtonText('Send message');
      setForm({
        nameContact: '',
        email: '',
        phoneNumber: '',
        subject: '',
        message: ''
      });
    }, 200);
  };

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.id]: event.target.value
    });
  }

  return (
    <Fade bottom duration={1000} distance="40px">
      <div className="main contact-margin-top" id="contact">
        <div className="contact-div-main">
          <div className="contact-header">
            <p
              className={
                isDark
                  ? "dark-mode contact-subtitle"
                  : "subTitle contact-subtitle"
              }
            >
              {contactInfo.subtitle}
            </p>
            <div className="heading contact-title">
              <h1 className="heading contact-title">{contactInfo.title}</h1>
              <form className="contact-container" onSubmit={handleSubmit}>
                <div className="div-form">
                  <label className="contact-label">Your Name *</label>
                  <input className="contact-input" id="nameContact" type="text" value={form.nameContact}
                    onChange={handleChange} required placeholder="Please enter your name" />
                  <label className="contact-label">Your Email *</label>
                  <input className="contact-input" id="email" type="email" value={form.email}
                    onChange={handleChange} required placeholder="Please enter your email" />
                  <label className="contact-label">Phone number</label>
                  <input className="contact-input" id="phoneNumber" type="text" value={form.phoneNumber}
                    onChange={handleChange} placeholder="Please enter your phone number" />
                  <label className="contact-label">Subject *</label>
                  <input className="contact-input" id="subject" type="text" value={form.subject}
                    onChange={handleChange} required placeholder="Please enter the subject" />
                  <label className="contact-label">Your Message *</label>
                  <textarea className="contact-textarea" id="message" type="text" value={form.message}
                    onChange={handleChange} required maxlength="300" placeholder="Leave your message..." />
                </div>
                <div className="div-button">
                  <input className="button-contact" type="submit"
                    value={buttonText}
                    disabled={isProcessing}
                  />
                </div>

              </form>
            </div>
            <div
              className={
                isDark ? "dark-mode contact-text-div" : "contact-text-div"
              }
            >
              <SocialMedia />
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
}
