import React, { useContext } from "react";
import "./Progress.scss";
import { techStack } from "../../portfolio";
import { Fade } from "react-reveal";
import StyleContext from "../../contexts/StyleContext";

export default function StackProgress() {
  const { isDark } = useContext(StyleContext);
  if (techStack.viewSkillBars) {
    return (
      <div id="tecSkills">
        <Fade bottom duration={1000} distance="40px">
          <div className="skills-container">
            <div className="skills-bar">
              <p
                className={
                  isDark
                    ? "dark-mode subTitle skills-text-subtitle"
                    : "subTitle skills-text-subtitle"
                }
              >
                {techStack.subTitle}
              </p>
              <h1 className="skills-heading">PROFICIENCY</h1>
              {techStack.experience.map((exp, i) => {
                const progressStyle = {
                  width: exp.progressPercentage
                };
                return (
                  <div key={i} className="skill">
                    <p>{exp.Stack}</p>
                    <div className="meter">
                      <span style={progressStyle}></span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Fade>
        <div className="skills-container-sub">
          {techStack.skills.map((skills, i) => {
            return (
              <p
                key={i}
                className={
                  isDark
                    ? "dark-mode subTitle skills-text"
                    : "subTitle skills-text"
                }
              >
                {skills}
              </p>
            );
          })}
        </div>
      </div>
    );
  }
  return null;
}
